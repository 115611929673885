import React from 'react';
import { GridSquare } from './Grid';
import { apiURL, currency, stripePromise } from "../App";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import payments from '../icons/payments.svg';

const StatusLabel = ({ taken = false } = {}) => (
	<p className={`uppercase rounded w-min px-1 font-bold text-sm my-1 ${taken ? 'text-black bg-slate-300' : 'text-white bg-green-500'}`}>
		{taken ? 'Taken' : 'Available'}
	</p>
);

function SquareDetail({ close, isWarning, square }: { close: () => void; isWarning: boolean; square: GridSquare | null }) {
	const [additionalDonation, setAdditionalDonation] = React.useState('');
	const [name, setName] = React.useState('');
	const [message, setMessage] = React.useState('');
	const [error, setError] = React.useState('');
	const [stripeError, setStripeError] = React.useState(false);

	const [clientSecret, setClientSecret] = React.useState('');

	const onAdditionalDonationInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const number = Number(e.target.value);
		console.log(number, e.target.value.startsWith('-'));
		if (Number.isNaN(number) || e.target.value.startsWith('-') || number < 0 || number > 9999999899 || (number * 100 % 1) !== 0) return;
		setAdditionalDonation(e.target.value);
	};

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setError('');

		if (message.length > 200) {
			setError('Message cannot exceed 200 characters.');
			return;
		}

		const url = new URL('/create-payment-intent', apiURL);
		console.log(url.toString());
		fetch(url, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				square: square!.number,
				additional: Number(additionalDonation) * 100 ?? 0,
				name,
				message,
			}),
		})
			.then(r => r.json())
			.then(r => {
				if (r.requiresPayment) {
					setClientSecret(r.clientSecret);
				} else {
					console.error('Unexpected payment intent status');
				}
			})
			.catch(err => {
				console.error(`Failed to create payment intent: ${err}`);
			});
	};

	React.useEffect(() => {
		stripePromise
			.then(() => setStripeError(false))
			.catch(() => setStripeError(true));
	}, []);

	React.useEffect(() => {
		document.body.style.overflow = square && window.innerWidth <= 768 ? 'hidden' : 'scroll';

		return () => {
			document.body.style.overflow = 'scroll';
		};
	});

	if (!square) return <React.Fragment></React.Fragment>;

	const availableDetail = (
		<React.Fragment>
			<StatusLabel />

			<div className="md:mt-8 mt-4 w-56 flex md:flex-col items-center md:items-start">
				<p className="text-3xl">{currency.format(square.number)}</p>
				<p className="mx-3 my-2 md:mt-0">+</p>
				<div className="relative shrink w-full">
					<input className="peer appearance-none shadow ring-1 ring-slate-200 rounded h-8 pl-4 focus:outline-green-700 md:w-full" type="text" inputMode="numeric" placeholder=" " value={additionalDonation} onInput={onAdditionalDonationInput} />
					<label className="peer-placeholder-shown:top-4 peer-focus:top-0 top-0 peer-placeholder-shown:text-sm peer-focus:text-xs text-xs peer-placeholder-shown:text-slate-500 peer-focus:text-black text-black transition-all bg-white absolute left-5 -translate-y-1/2 pointer-events-none" style={{ lineHeight: 1 }}>Additional donation</label>
					<label className="absolute top-4 -translate-y-1/2 left-1 text-black peer-placeholder-shown:text-slate-500 peer-focus:text-black">$</label>
				</div>
			</div>

			<form className="mt-8" onSubmit={onSubmit}>
				<div className="relative">
					<input className="peer appearance-none shadow ring-1 ring-slate-200 rounded w-full md:w-56 h-10 pl-2 focus:outline-green-700" type="text" placeholder=" " value={name} onChange={e => setName(e.target.value)} />
					<label className="peer-placeholder-shown:top-1/2 peer-focus:top-0 top-0 peer-placeholder-shown:text-md peer-focus:text-sm text-sm peer-placeholder-shown:text-slate-500 peer-focus:text-black text-black transition-all bg-white absolute left-2 -translate-y-1/2 pointer-events-none" style={{ lineHeight: 1 }}>Name</label>
				</div>

				<div className="relative my-[0.65rem]">
					<textarea className="peer appearance-none shadow ring-1 ring-slate-200 rounded md:w-56 w-full h-32 md:h-36 px-2 pt-1 focus:outline-green-700 min-h-[2rem] resize-none" placeholder=" " value={message} onChange={e => setMessage(e.target.value)}></textarea>
					<label className="peer-placeholder-shown:top-4 peer-focus:top-0 top-0 peer-placeholder-shown:text-md peer-focus:text-sm text-sm peer-placeholder-shown:leading-none text-black peer-placeholder-shown:text-slate-500 peer-focus:text-black transition-all bg-white absolute left-2 -translate-y-1/2 pointer-events-none" style={{ lineHeight: 1 }}>Message</label>
					<label className={`text-right block md:w-56 w-full text-xs leading-none ${message.length > 200 ? 'text-red-500' : ''}`}>200 max</label>
				</div>

				{error && <p className="mx-auto md:mx-0 w-max mb-2 md:mb-3 text-center md:text-left">{error}</p>}

				{isWarning && <p className="mx-auto md:mx-0 w-max mb-2 md:mb-3 text-center md:text-left">Someone else is buying this square.<br />Try to buy it first!</p>}

				<button className="w-full md:max-w-[14rem] md:w-56 h-12 md:h-10 bg-gradient-to-r from-green-500 to-emerald-500 disabled:from-green-700 disabled:to-emerald-700 disabled:text-white/75 disabled:cursor-not-allowed hover:bg-green-600 hover:shadow-md transition rounded text-white font-semibold shadow flex justify-center items-center" disabled={stripeError}>
					<img src={payments} className="h-6 w-6 mr-2" alt="" />
					Continue to payment
				</button>
			</form>
		</React.Fragment>
	);

	const additional = (square.additionalDonation ?? 0) / 100;

	const takenDetail = (
		<React.Fragment>
			<StatusLabel taken />

			<div className="md:mt-8 mt-4 md:w-56 flex md:flex-col items-center md:items-start">
				<p className="text-3xl">{currency.format(square.number)}</p>
				{!!additional &&
					<React.Fragment>
						<p className="mx-3">+</p>
						<p className="text-xl">{currency.format(additional)} additional</p>
					</React.Fragment>
				}
			</div>

			<div className="mt-4">
				<h2 className="text-xl font-semibold">{square.displayName || 'Anonymous'}</h2>
				<p className="text-lg w-full md:w-56">{square.message ?? ''}</p>
			</div>
		</React.Fragment>
	);

	return (
		<div className={`fixed bottom-0 right-0 md:static w-full md:w-96 md:h-full h-3/4 bg-white md:bg-transparent rounded-t-2xl ring-1 md:ring-0 ring-slate-300 transition px-6 py-5 md:px-6 md:py-2 overflow-scroll ${square ? 'translate-y-0' : 'translate-y-full'} ${clientSecret ? '' : ''}`}>
			<button className="absolute top-5 right-5 md:hidden" onClick={close}>
				<svg viewBox="0 0 100 100" className="w-9 h-9">
					<circle cx={50} cy={50} r={50} fill="#e6e6e6" />
					<line x1={30} y1={30} x2={70} y2={70} strokeWidth={10} strokeLinecap="round" stroke="black" />
					<line x1={70} y1={30} x2={30} y2={70} strokeWidth={10} strokeLinecap="round" stroke="black" />
				</svg>
			</button>

			<div>
				<h1 className="text-3xl font-bold">Square {square.number}</h1>
				{square.status === 'available' ? availableDetail : takenDetail}

				{clientSecret &&
					<Elements stripe={stripePromise} options={{ clientSecret, appearance: {
						theme: 'stripe'
					}
					}}>
						<CheckoutForm clientSecret={clientSecret} close={() => setClientSecret('')} data={{ square, name, message, additionalDonation: Number(additionalDonation) }} />
					</Elements>
				}
			</div>
		</div>
	);
}

export default SquareDetail;
