import React from 'react';
import ReactDOM from 'react-dom/client';
import { init as sentryInit, Replay } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import './index.css';

sentryInit({
	dsn: 'https://70ff78e429f04087bc54c0ef60f1f74e@o309249.ingest.sentry.io/4504781199376384',
	integrations: [new BrowserTracing(), new Replay()],
	tracesSampleRate: 1.0,
	replaysOnErrorSampleRate: 1.0,
	replaysSessionSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);
