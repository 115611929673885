import React from 'react';
import Grid, { GridSquare } from './components/Grid';
import SquareDetail from './components/SquareDetail';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import banner from './banner.webp';

export const currency = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
// export const stripePromise = loadStripe('pk_test_51Mf8cSFpyEEP4j0rOky8LBYARju3fILos6yvUcxzqX2QDMlolvKHHqOUO1hX0xeY4zAm3UAYXKtMlSG1lqV2WGA000JI24GxNn');
export const stripePromise = loadStripe('pk_live_pvk547NSq4JBgJ1Gst3685IJ');

export const apiURL = window.location.hostname === 'fundraising.pensacolachs.org'
	? 'https://api.fundraising.pensacolachs.org/'
	: `${window.location.protocol}//${window.location.hostname}:8000`;

interface GridState {
	squares: GridSquare[];
}

const querySquare = Number(new URL(window.location.toString()).searchParams.get('square') ?? '0');

function App() {
	const [gridState, setGridState] = React.useState<GridState | null>(null);
	const [warnings, setWarnings] = React.useState(new Set<number>());
	const [totalRaised, setTotalRaised] = React.useState(0);

	React.useEffect(() => {
		/*fetch('http://localhost:8080/create-payment-intent', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				square: 45,
				additional: 1500,
			}),
		})
		.then(r => r.json())
		.then(r => {
			if (r.error) {

			} else {
				setClientSecret(r.clientSecret);
			}
		});
		*/
		let websocket: WebSocket | null = null;
		let heartbeatTimer = -1;

		const setupWebSocket = (onClose: () => void) => {
			const readyState = websocket?.readyState ?? WebSocket.CLOSED;
			if (readyState === WebSocket.CONNECTING || readyState === WebSocket.OPEN) {
				console.debug('Websocket connecting or open');
				return;
			}

			const write = (socket: WebSocket) => (message: any) => {
				if (socket.readyState !== WebSocket.OPEN) {
					console.debug('Socket is not open');
					return;
				}

				if (typeof message === 'string') socket.send(message);
				socket.send(JSON.stringify(message));
			};

			const url = new URL(apiURL);
			url.search = '';
			url.protocol = url.protocol === 'https:' ? 'wss' : 'ws';
			url.pathname = '/ws';

			const ws = new WebSocket(url.toString());
			websocket = ws;
			const send = write(ws);

			ws.onmessage = message => {
				const raw = message.data.toString();

				let data: { op: string; d: any; };
				try {
					data = JSON.parse(raw);
				} catch (e) {
					console.error('Failed to decode:', e);
					ws.close(4000, 'decode error');
					return;
				}

				if (data.op === 'hello') {
					const { heartbeatInterval, squares, totalRaised: tr, warnings: w } = data.d;

					setWarnings(warnings => {
						for (const warning of w) warnings.add(warning);
						return warnings;
					});

					setGridState({ squares });
					setTotalRaised(tr);
					console.debug('Received hello');

					if (heartbeatTimer) clearInterval(heartbeatTimer);

					heartbeatTimer = window.setInterval(() => {
						console.debug('Heartbeat');

						send({
							op: 'heartbeat',
						});
					}, heartbeatInterval);
				} else if (data.op === 'update') {
					console.log(data.d.length);
					setGridState({ squares: data.d.squares });
					setTotalRaised(data.d.totalRaised);
				} else if (data.op === 'warn') {
					setWarnings(warnings => warnings.add(data.d));
					console.log('New warning:', data.d);
				} else if (data.op === 'stopWarn') {
					setWarnings(w => {
						w.delete(data.d);
						return w;
					});

					console.log('Canceled warning:', data.d);
				} else {
					console.warn('Unknown opcode:', data.op, data);
				}
			};

			ws.onclose = () => {
				websocket = null;
				window.clearInterval(heartbeatTimer);
				console.warn('Socket closed');
				onClose();
			};
		};

		let attempts = 0;
		const onClose = () => {
			setTimeout(() => {
				setupWebSocket(onClose);
			}, (attempts++) * 250);
		};

		setupWebSocket(onClose);
	}, []);

	const [selectedSquare, setSelectedSquare] = React.useState<number | null>(querySquare && querySquare > 0 && querySquare <= 100 && Number.isInteger(querySquare) ? querySquare : (window.innerWidth <= 768 ? null : 1));
	const [offline, setOffline] = React.useState(!navigator.onLine);

	const [stripeError, setStripeError] = React.useState('');
	React.useEffect(() => {
		stripePromise.catch(err => {
			console.error('Failed to load Stripe.js:', err);
			setStripeError('Failed to load Stripe');
			return null;
		});
	}, []);

	window.addEventListener('online', () => setOffline(false));
	window.addEventListener('offline', () => setOffline(true));

	return (
		<Elements stripe={stripePromise}>
			<div>

			</div>

			<div className="m-2 md:m-6">
				{offline ? <div className={`fixed bottom-4 left-4 mr-4 max-w-[min(500px,100vw)] z-30 px-4 py-2 bg-red-200 rounded ring-1 ring-red-500 cursor-pointer group transition-all ${offline ? 'translate-y-0' : 'translate-y-[150%]'}`} onClick={() => setOffline(false)}>
					<div className="uppercase absolute top-0 left-0 w-full h-full flex items-center justify-center transition text-xs leading-none font-bold text-red-200 bg-red-300/0 group-hover:bg-red-500/90">
						<p className="text-transparent group-hover:text-black transition">Dismiss</p>
					</div>

					<p>You appear to be offline. <br /> Squares will not update automatically.</p>
				</div>

				: <div className={`fixed bottom-4 left-4 mr-4 max-w-[min(500px,100vw)] z-30 px-4 py-2 bg-red-200 rounded ring-1 ring-red-500 cursor-pointer group transition-all ${stripeError ? 'translate-y-0' : 'translate-y-[150%]'}`} onClick={() => setStripeError('')}>
					<div className="uppercase absolute top-0 left-0 w-full h-full flex items-center justify-center transition text-xs leading-none font-bold text-red-200 bg-red-300/0 group-hover:bg-red-500/90">
						<p className="text-transparent group-hover:text-black transition">Dismiss</p>
					</div>

					<p>Failed to load Stripe. <br/> Try again in a few minutes.</p>
				</div>}


				{/*{clientSecret && <Elements stripe={stripePromise} options={{clientSecret}}>
					<CheckoutForm clientSecret={clientSecret} />
				</Elements>}*/}

				<div className="mx-auto max-w-4xl">
					<div className="flex flex-col items-center mb-2">
						{/*<div className="flex items-center">
							<img className="w-20 h-20" src={logo} alt="" />

							<h1 className="font-bold text-4xl ml-4">Crusader Baseball</h1>
						</div>*/}
						<img className="max-w-md w-full mb-4" src={banner} alt="" />

						<div className="w-full max-w-md my-2 h-4 ring-1 ring-slate-100 rounded-full shadow-sm overflow-hidden">
							<div className="bg-green-700 h-full rounded-full" style={{ width: `${Math.min(totalRaised / 505000, 1) * 100}%`}}></div>
						</div>

						<p className="mb-2 text-green-700 text-xl font-semibold">{currency.format(totalRaised / 100)} raised of {currency.format(5050)} goal</p>
					</div>

					<div className="relative flex">
						<div className="w-full">
							<Grid square={[selectedSquare, setSelectedSquare]} squares={gridState?.squares ?? []} />
						</div>

						<SquareDetail isWarning={warnings.has(selectedSquare ?? -1)} close={() => setSelectedSquare(null)} square={(gridState?.squares ?? [])[selectedSquare! - 1] ?? null} />
					</div>
				</div>
			</div>
		</Elements>
	);
}

export default App;
