import React from 'react';
import ReactDOM from "react-dom";
import { currency, apiURL } from "../App";
import {
	useStripe, useElements,
	AddressElement,
	PaymentElement,

} from '@stripe/react-stripe-js';
import Loader from '../icons/loader';
import { GridSquare } from "./Grid";

function CheckoutForm({ clientSecret, close: closeForm, data }: { clientSecret: string; close: () => void; data: { square: GridSquare; additionalDonation: number; name: string; message: string } }) {
	const stripe = useStripe();
	const elements = useElements();

	const [error, setError] = React.useState('');

	React.useEffect(() => {
		document.body.style.overflow = 'clip';

		return () => {
			document.body.style.overflow = 'scroll';
		};
	});

	/* const [paymentRequest, setPaymentRequest] = React.useState<PaymentRequest | null>(null);

	React.useEffect(() => {
		if (stripe) {
			const pr = stripe.paymentRequest({
				country: 'US',
				currency: 'usd',
				total: {
					label: `Square ${data.square.number} + $${data.additionalDonation}`,
					amount: data.square.number * 100 + data.additionalDonation,
				},
				requestPayerName: true,
				requestPayerEmail: true,
				requestPayerPhone: true,
			});

			pr.canMakePayment().then(result => {
				if (result) {
					setPaymentRequest(pr);
				}
			});
		}
	}, [stripe, data]); */

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();

		if (!stripe || !elements) {
			return console.error('Handle submit called when null stripe or elements');
		}

		const addressElement = elements!.getElement('address');
		const { complete } = await addressElement!.getValue();

		if (!complete) return;

		setIsLoading(true);

		const payURL = new URL('/pay', apiURL);
		const lockResponse = await fetch(payURL, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({
				clientSecret: clientSecret,
				lock: data.square.number,
			}),
		});

		if (!lockResponse.ok) {
			const body = await lockResponse.json();
			console.debug('Unable to lock square', data.square.number);
			setError(body.message);
			setIsLoading(false);
			return;
		}

		const url = new URL(apiURL);
		url.pathname = '/complete';

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: url.toString(),
				receipt_email: '',
			},
		});

		if (error) {
			console.error(error);
			setError(error.message ?? '');
			await elements.fetchUpdates();
		}

		setIsLoading(false);
	};

	const [isLoading, setIsLoading] = React.useState(false);

	const element = (
		<div className="fixed top-0 left-0 w-full h-full bg-black/50 overflow-scroll">
			<div className="absolute top-1/2 left-1/2 w-[32rem] shadow-xl max-w-full -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg p-6 max-h-full overflow-scroll">
				<form onSubmit={handleSubmit} className="rounded-lg max-w-lg">
					<div className="flex justify-between items-center mb-4">
						<h3 className="text-2xl font-semibold">Payment</h3>

						<button className="block z-30" onClick={closeForm}>
							<svg viewBox="0 0 100 100" className="w-9 h-9">
								<circle cx={50} cy={50} r={50} fill="#e6e6e6" />
								<line x1={30} y1={30} x2={70} y2={70} strokeWidth={10} strokeLinecap="round" stroke="black" />
								<line x1={70} y1={30} x2={30} y2={70} strokeWidth={10} strokeLinecap="round" stroke="black" />
							</svg>
						</button>
					</div>

					<AddressElement options={{
						mode: 'billing',
						allowedCountries: ['US'],
						fields: { phone: 'auto' },
					}} />

					<PaymentElement options={{
						fields: {
							billingDetails: { email: 'auto' }
						},
					}} />

					<button type="submit" className="relative bg-gradient-to-r from-green-500 to-emerald-500 mt-3 text-white rounded-md w-full py-3 font-semibold">
						<span className={isLoading ? 'opacity-0' : 'opacity-100'}>Donate {currency.format(data.additionalDonation + data.square.number)}</span>
						{isLoading && <Loader className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-12 h-12" />}
					</button>

					{error && <div className="text-red-500 mx-auto w-fit mt-2 text-center">{error}</div>}
				</form>
			</div>
		</div>
	);

	return ReactDOM.createPortal(element, document.getElementById('payment-root')!);
}

export default CheckoutForm;
