import React from 'react';

export type GridSquare = {
	number: number;
	status: 'available';
	paymentIntentID?: null;
	displayName: null;
	message: null;
	additionalDonation: null;
	paymentSucceeded?: false;
} | {
	number: number;
	status: 'taken';
	paymentIntentID?: string;
	displayName: string | null;
	message: string | null;
	additionalDonation: number;
	paymentSucceeded?: boolean
}

const image = [
	0, 0, 0, 1, 1, 1, 1, 0, 0, 0,
	0, 1, 1, 1, 0, 1, 1, 1, 1, 0,
	0, 1, 0, 0, 0, 1, 0, 0, 1, 0,
	1, 1, 0, 0, 0, 0, 1, 0, 1, 1,
	1, 1, 1, 1, 0, 0, 0, 1, 0, 1,
	1, 0, 0, 0, 1, 0, 0, 0, 1, 1,
	1, 1, 0, 0, 0, 1, 0, 0, 1, 1,
	0, 1, 0, 0, 0, 1, 0, 0, 1, 0,
	0, 1, 1, 1, 0, 1, 1, 1, 1, 0,
	0, 0, 0, 1, 1, 1, 1, 0, 0, 0,
];

interface GridProps {
	square: [
		number | null,
		(square: number | null) => void,
	];
	squares: GridSquare[];
}

function Grid({ square: [selectedSquare, setSelectedSquare], squares }: GridProps) {
	return (
		<div>
			<div className="p-1.5 sm:p-0.5 shadow-inner bg-slate-50 aspect-square rounded-[14px] grid grid-rows-[repeat(10,1fr)] grid-cols-[repeat(10,1fr)] ring-1 ring-gray-200">
				{squares.map(square => {
					const { number: n } = square;

					// const isGreen = (square.number + Math.floor(square.number / 10)) % 2 === 0;
					const isGreen = image[square.number - 1] === 1;

					return (
						<button onClick={() => {
							setSelectedSquare(n);
						}} key={n} onSelect={() => setSelectedSquare(n)} tabIndex={0} className={`m-[0.1rem] select-all sm:m-[0.3rem] flex rounded-md sm:rounded-lg text-md sm:text-lg relative transition-all duration-300 ${isGreen ? 'sm:hover:bg-green-600 outline-green-600' : 'sm:hover:bg-yellow-400 outline-yellow-500'} ${square.status === 'taken' ? `shadow-inner ring-slate-500 bg-green-700 outline-slate-500 text-white ${isGreen ? 'bg-green-700' : 'bg-yellow-500'}` : 'shadow sm:hover:shadow-lg bg-white'} duration-150 cursor-pointer items-center justify-center ${selectedSquare === n ? `ring ${isGreen ? 'ring-green-600' : 'ring-yellow-500'}` : ''}`}>
							<p className="select-none">{square.number}</p>
						</button>
					);
				})}

				{!squares.length &&
				<div className="loader absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">

				</div>
				}
			</div>
		</div>
	);
}

export default Grid;
